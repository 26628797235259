import React, { forwardRef, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { Select } from "clutch/src/Select/Select.jsx";
import { Tag } from "clutch/src/Tag/Tag.jsx";

import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import { ACTIVE_DUTY_MAPS, MAPS } from "@/game-cs2-marketing/constants.mjs";
import { getGrenadeIcon, getMapImage } from "@/game-cs2-marketing/utils.mjs";
import { GAME_SYMBOL_CS2 } from "@/game-csgo/definition-symbol.mjs";
import BlitzFilter from "@/inline-assets/blitz-filter.svg";
import ChevronDown from "@/inline-assets/chevron-down.svg";
import ChevronUp from "@/inline-assets/chevron-up.svg";
import Close from "@/inline-assets/close.svg";
import CollapseLeft from "@/inline-assets/collapse-left.svg";
import ExpandLeft from "@/inline-assets/expand-left.svg";
import NoLineups from "@/shared-fps/NoLineups.jsx";
import { classNames } from "@/util/class-names.mjs";
import { useIsLoaded } from "@/util/router-hooks.mjs";

const Lineups = styled("div", forwardRef)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const LineupContainer = styled("div")`
  display: flex;
  flex-direction: row;
  gap: var(--sp-4);
  position: relative;
  cursor: pointer;
  padding: var(--sp-6);

  &.selected {
    background: var(--shade7);
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: var(--sp-1);
    height: 100%;
    background: var(--blue);
    display: none;
  }

  &.selected::before {
    display: block;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: var(--sp-3);

    .description {
      color: var(--shade2);
      max-height: var(--sp-15);
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }
  }

  &.selected > .content > .description {
    max-height: max-content;
  }

  .grenade-image {
    svg {
      width: var(--sp-10);
      height: var(--sp-10);
    }
    border-radius: 50%;
    background: var(--shade9);
    height: fit-content;
  }

  .caret {
    svg {
      color: var(--shade2);
      width: var(--sp-3_5);
      height: var(--sp-3_5);
    }
  }

  .labels {
    display: flex;
    flex-direction: row;
    gap: var(--sp-1);
    flex-wrap: wrap;
  }
`;

const DifficultyTag = styled(Tag)`
  span.difficulty {
    display: block;
    height: var(--sp-1_5);
    width: var(--sp-1_5);
    margin: var(--sp-1_5);
    border-radius: 100%;
    &[data-difficulty="Easy"] {
      background: var(--green);
    }
    &[data-difficulty="Medium"] {
      background: var(--yellow);
    }
    &[data-difficulty="Hard"] {
      background: var(--red);
    }
  }
  &.filter {
    cursor: pointer;
    &:hover,
    &[data-selected="true"] {
      background: var(--shade0-15);
    }
  }
`;

const Container = styled("div")`
  display: flex;
  flex-direction: row;
  background: transparent;
  border-radius: var(--br-lg);
  height: 100%;
  width: calc(var(--sp-10) * 8.2);

  .expander {
    cursor: pointer;
    background: var(--shade8);
    display: none;
    height: fit-content;
    padding: var(--sp-4_5);
    margin-top: var(--sp-6);
    border-radius: var(--br-lg);
    svg {
      width: var(--sp-6);
      height: var(--sp-6);
    }
    &.expanded {
      border-radius: 0 var(--br-lg) var(--br-lg) 0;
    }
  }

  .left {
    background: var(--shade8);
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  @container content-container (width <= 700px) {
    .expander {
      display: block;
    }

    .left {
      display: none;
      &.expanded {
        display: flex;
      }
    }
    height: fit-content;
    &.expanded {
      height: 100%;
    }
  }
`;

const MapFilters = styled("div")`
  width: 100%;
  height: calc(4.5 * var(--sp-10));
  padding: var(--sp-5) var(--sp-6);
  display: flex;
  gap: var(--sp-4);
  flex-direction: column;
  .select-container {
    width: 100%;
    button.selectButton {
      width: 100%;
      padding-left: 0;

      .image > img {
        width: var(--sp-8);
        height: 100%;
        margin: auto;
        border-radius: var(--br) 0 0 var(--br);
      }
    }
  }
  .button-filters {
    position: relative;
    width: 100%;
    button {
      flex: 1;
    }
    .more {
      flex: 0;
      svg {
        width: var(--sp-4);
        height: var(--sp-4);
      }
    }
  }
`;

const MoreFilters = styled("div")`
  position: absolute;
  right: calc(-1 * var(--sp-4));
  top: calc(-1 * var(--sp-2));

  z-index: 3;
  background: var(--shade10);
  padding: var(--sp-4);
  border-radius: var(--br);

  display: flex;
  flex-direction: column;
  gap: var(--sp-4);

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;

    p:first-child {
      flex: 1;
    }

    svg {
      width: var(--sp-5);
      height: var(--sp-5);
      color: var(--shade3);
      cursor: pointer;
    }
  }

  .content {
    p.header {
      color: var(--shade2);
      margin-bottom: var(--sp-2);
      margin-top: var(--sp-4);
    }
    p.header:first-child {
      margin-top: 0;
    }
    .tags {
      display: flex;
      flex-direction: row;
      gap: var(--sp-1);
    }
    .visibility-options {
      display: flex;
      flex-direction: column;
      gap: var(--sp-2);

      .radio-option {
        display: flex;
        align-items: center;
        gap: var(--sp-2);

        input {
          appearance: none;
          margin: 0;
        }

        .outer-circle {
          width: var(--sp-4);
          height: var(--sp-4);

          display: flex;
          border-radius: 50%;
          border: var(--sp-0_5) solid var(--shade3);

          &.selected {
            border: var(--sp-0_5) solid var(--blue);
          }
        }

        .inner-circle {
          width: var(--sp-2);
          height: var(--sp-2);

          margin: auto;
          border-radius: 50%;
          &.selected {
            background-color: var(--blue);
          }
        }
      }
    }
  }
`;

function Lineup({ id, isSelected, lineup, onClick }) {
  const GrenadeSVG = getGrenadeIcon(lineup.grenade);
  return (
    <LineupContainer
      className={isSelected ? "selected" : ""}
      onClick={onClick}
      id={id}
    >
      <div className="grenade-image">
        <GrenadeSVG />
      </div>
      <div className="content">
        <p className="type-body2-form--bold title">{lineup.title}</p>
        <p className="type-callout description">{lineup.description}</p>
        <div className="labels">
          <DifficultyTag
            size="small"
            text={lineup.difficulty}
            color="var(--shade1)"
            iconLeft={
              <span
                className="difficulty"
                data-difficulty={lineup.difficulty}
              />
            }
          />
          <DifficultyTag
            size="small"
            text={lineup.action}
            color="var(--shade1)"
          />
        </div>
      </div>
      <div className="caret">
        {isSelected ? <ChevronUp /> : <ChevronDown />}
      </div>
    </LineupContainer>
  );
}

function LineupsList(
  {
    filteredLineups,
    side,
    setSide,
    grenadeType,
    setGrenadeType,
    selectedMap,
    setSelectedMap,
    selectedLineup,
    setSelectedLineup,
    grenadePosition,
    setGrenadePosition,
    difficulty,
    setDifficulty,
    className,
  },
  ref,
) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const onChangeGrenadePosition = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setGrenadePosition(e.target.value);
    },
    [setGrenadePosition],
  );
  const isLoaded = useIsLoaded();
  const options = Object.entries(MAPS)
    .filter(([id]) => ACTIVE_DUTY_MAPS.includes(id))
    .map(([id, m]) => {
      return {
        value: id,
        text: [m.i18nKey, m.name] satisfies Translation,
        image: getMapImage(id),
      };
    });

  return (
    <Container
      className={classNames(className, expanded ? "expanded" : "").className}
    >
      <div className={classNames("left", expanded ? "expanded" : "").className}>
        <MapFilters>
          <Select
            containerClassName="select-container"
            onChange={(value) => setSelectedMap(value)}
            selected={selectedMap}
            options={options}
          />
          <ButtonGroup className="button-filters">
            <Button
              active={grenadeType === "Smoke"}
              onClick={() => setGrenadeType("Smoke")}
            >
              {t("cs2:grenades.smoke", "Smoke")}
            </Button>
            <Button
              active={grenadeType === "Flashbang"}
              onClick={() => setGrenadeType("Flashbang")}
            >
              {t("cs2:grenades.flash", "Flashbang")}
            </Button>
            <Button
              active={grenadeType === "Molotov"}
              onClick={() => setGrenadeType("Molotov")}
            >
              {t("cs2:grenades.molotov", "Molotov")}
            </Button>
          </ButtonGroup>
          <ButtonGroup className="button-filters">
            <Button active={side === "Both"} onClick={() => setSide("Both")}>
              {t("common:both", "Both")}
            </Button>
            <Button active={side === "T"} onClick={() => setSide("T")}>
              {t("cs2:terroristsShort", "T")}
            </Button>
            <Button active={side === "CT"} onClick={() => setSide("CT")}>
              {t("cs2:counterTerroristsShort", "CT")}
            </Button>
            <Button
              onClick={() => setShowMoreFilters(!showMoreFilters)}
              className="more"
            >
              <BlitzFilter />
            </Button>
            {showMoreFilters ? (
              <MoreFilters>
                <div className="header">
                  <p className="type-subtitle--bold">
                    {t("common:filters", "Filters")}
                  </p>
                  <Close onClick={() => setShowMoreFilters(false)} />
                </div>
                <div className="content">
                  <p className="type-overline header">
                    {t("common:difficulty", "Difficulty")}
                  </p>
                  <div className="tags">
                    <DifficultyTag
                      size="small"
                      text={t("common:difficulties.all", "All")}
                      color="var(--shade1)"
                      data-selected={difficulty === "All"}
                      onClick={() => setDifficulty("All")}
                      className="filter"
                    />
                    <DifficultyTag
                      size="small"
                      text={t("common:difficulties.easy", "Easy")}
                      color="var(--shade1)"
                      iconLeft={
                        <span className="difficulty" data-difficulty={"Easy"} />
                      }
                      data-selected={difficulty === "Easy"}
                      onClick={() => setDifficulty("Easy")}
                      className="filter"
                    />
                    <DifficultyTag
                      size="small"
                      text={t("common:difficulties.medium", "Medium")}
                      color="var(--shade1)"
                      iconLeft={
                        <span
                          className="difficulty"
                          data-difficulty={"Medium"}
                        />
                      }
                      data-selected={difficulty === "Medium"}
                      onClick={() => setDifficulty("Medium")}
                      className="filter"
                    />
                    <DifficultyTag
                      size="small"
                      text={t("common:difficulties.hard", "Hard")}
                      color="var(--shade1)"
                      iconLeft={
                        <span className="difficulty" data-difficulty={"Hard"} />
                      }
                      data-selected={difficulty === "Hard"}
                      onClick={() => setDifficulty("Hard")}
                      className="filter"
                    />
                  </div>
                  <p className="type-overline header">
                    {t("common:visibility", "Visibility")}
                  </p>
                  <div className="visibility-options">
                    <label className="radio-option type-caption--semi">
                      <input
                        type="radio"
                        name="grenade-position"
                        id="end"
                        value="end"
                        onChange={onChangeGrenadePosition}
                      />
                      <div
                        className={
                          classNames(
                            "outer-circle",
                            grenadePosition === "end" ? "selected" : "",
                          ).className
                        }
                      >
                        <div
                          className={
                            classNames(
                              "inner-circle",
                              grenadePosition === "end" ? "selected" : "",
                            ).className
                          }
                        />
                      </div>
                      {t(
                        "cs2:grenadeVisibility.grenadeEndPosition",
                        "Grenade End Position",
                      )}
                    </label>
                    <label className="radio-option type-caption--semi">
                      <input
                        type="radio"
                        name="grenade-position"
                        id="throwing"
                        value="throwing"
                        onChange={onChangeGrenadePosition}
                      />
                      <div
                        className={
                          classNames(
                            "outer-circle",
                            grenadePosition === "throwing" ? "selected" : "",
                          ).className
                        }
                      >
                        <div
                          className={
                            classNames(
                              "inner-circle",
                              grenadePosition === "throwing" ? "selected" : "",
                            ).className
                          }
                        />
                      </div>
                      {t(
                        "cs2:grenadeVisibility.throwingPosition",
                        "Throwing Position",
                      )}
                    </label>
                  </div>
                </div>
              </MoreFilters>
            ) : null}
          </ButtonGroup>
        </MapFilters>
        <Lineups ref={ref}>
          {filteredLineups.map((l) => {
            return (
              <Lineup
                key={l.id}
                isSelected={selectedLineup?.id === l.id}
                lineup={l}
                onClick={() =>
                  selectedLineup?.id === l.id
                    ? setSelectedLineup(null)
                    : setSelectedLineup(l.id)
                }
                id={l.id}
              />
            );
          })}
          {filteredLineups.length === 0 && isLoaded ? (
            <NoLineups
              href={`/${GAME_SHORT_NAMES[GAME_SYMBOL_CS2]}/tools/grenade-lineups`}
              onClick={undefined}
              description={t(
                "cs2:noLineups",
                "There are currently no lineups available for this map. Check back soon!",
              )}
              goBackText={t("cs2:goBackToMapLineups", "Go Back to Map Lineups")}
            />
          ) : null}
        </Lineups>
      </div>
      <div
        className={classNames("expander", expanded ? "expanded" : "").className}
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? <CollapseLeft /> : <ExpandLeft />}
      </div>
    </Container>
  );
}

export default forwardRef(LineupsList);
