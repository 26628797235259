import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";

import { appURLs } from "@/app/app-urls.mjs";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  padding: var(--sp-5) var(--sp-6);
  align-items: center;
  text-align: center;
  gap: var(--sp-6);
`;

function NoLineups({ description, goBackText, href, onClick }) {
  const { t } = useTranslation();
  return (
    <Container>
      <img
        src={`${appURLs.CDN_PLAIN}/blitz/val/misc/lineups-placeholder.svg`}
        alt="lineups placeholder"
      />
      <div>
        <p className="type-subtitle1 shade0">
          {t("val:lineups.noLineupsFound", "No Lineups Found")}
        </p>
        <p className="type-caption shade1">{description}</p>
      </div>
      <Button onClick={onClick} href={href}>
        {goBackText}
      </Button>
    </Container>
  );
}

export default NoLineups;
