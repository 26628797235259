import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import {
  TransformComponent,
  TransformWrapper,
} from "@pronestor/react-zoom-pan-pinch";

import { readState } from "@/__main__/app-state.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import { HeaderIcon } from "@/game-cs2-marketing/CommonComponents";
import { MAPS } from "@/game-cs2-marketing/constants.mjs";
import { getMapImage } from "@/game-cs2-marketing/utils.mjs";
import GrenadeLineupsList from "@/game-csgo/GrenadeLineupsList.jsx";
import GrenadeLineupsMap, { MAP_SIZE } from "@/game-csgo/GrenadeLineupsMap.jsx";
import GrenadeLineupsVideo from "@/game-csgo/GrenadeLineupsVideo.jsx";
import useLineupTip from "@/game-csgo/useLineupTip";
import PageHeader from "@/shared/PageHeader";
import MapControls from "@/shared-fps/MapControls.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const PageContainer = styled("div")`
  container-type: inline-size;
  container-name: content-container;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: min-content minmax(0, 1fr);
  grid-gap: var(--sp-1);
  width: 100%;
  --padd: var(--sp-4);
  max-width: calc(var(--sp-container) + var(--padd) * 2);

  position: absolute;
  inset: 0 0 0 0;
  margin: var(--sp-4) auto;
  overflow: hidden;
  padding-inline: var(--padd);

  .header {
    grid-column: span 3;
  }

  .lineups-list {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    z-index: 2;
  }

  .map-container {
    position: relative;
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    height: 100%;
    max-height: ${MAP_SIZE}px;
    border: 1px solid var(--shade3);
    border-radius: var(--br-lg);

    .react-transform-wrapper {
      height: 100%;
      width: 100%;
    }

    .react-transform-component {
      margin: 0 auto;
    }
  }

  @container (width <= 700px) {
    .map-container {
      grid-column: 1 / 4;
      border: none;
    }
  }
`;

function GrenadeLineups() {
  const { t } = useTranslation();
  const {
    cs2: { lineups: allLineups },
  } = useSnapshot(readState);
  const { searchParams, currentPath } = useRoute();

  const updateRouteParams = useCallback(
    (key, value) => {
      if (value === null) {
        searchParams.delete(key);
      } else searchParams.set(key, value);
      updateRoute(currentPath, searchParams);
    },
    [currentPath, searchParams],
  );

  const selectedMap = searchParams.get("map") || "de_mirage";
  const setSelectedMap = (map) => updateRouteParams("map", map);
  const selectedLineupId = searchParams.get("lineup");
  const side = searchParams.get("side") || "Both";
  const setSide = (value) => updateRouteParams("side", value);
  const grenadeType = searchParams.get("grenade") || "Smoke";
  const setGrenadeType = (value) => updateRouteParams("grenade", value);
  const grenadePosition = searchParams.get("position") || "end";
  const setGrenadePosition = (value) => updateRouteParams("position", value);
  const difficulty = searchParams.get("difficulty") || "All";
  const setDifficulty = (value) => updateRouteParams("difficulty", value);

  const lineups =
    !(allLineups[selectedMap] instanceof Error) &&
    allLineups[selectedMap]?.length
      ? allLineups[selectedMap]
      : [];
  const filteredLineups = lineups.filter(
    (l) =>
      grenadeType === l.grenade &&
      (side !== "Both" ? l.side === side : true) &&
      (difficulty !== "All" ? l.difficulty === difficulty : true),
  );
  const selectedLineup = lineups.find((l) => l.id === selectedLineupId);
  const showSelectedLineup = !!filteredLineups.find(
    (l) => l.id === selectedLineupId,
  );
  const lineupsRef = useRef(null);
  const setSelectedLineup = (value) => updateRouteParams("lineup", value);
  useLineupTip(selectedLineup);

  useEffect(() => {
    if (selectedLineupId && lineupsRef.current) {
      for (const lineup of lineupsRef.current.childNodes.values()) {
        if (lineup.id === selectedLineupId) {
          lineup.scrollIntoView({ behaviour: "smooth" });
        }
      }
    }
  }, [selectedLineupId]);

  const map = Object.entries(MAPS).find(([mapId]) => mapId === selectedMap)[1];

  const lineupListProps = {
    filteredLineups,
    side,
    setSide,
    selectedMap,
    setSelectedMap,
    grenadeType,
    setGrenadeType,
    selectedLineup,
    setSelectedLineup,
    grenadePosition,
    setGrenadePosition,
    difficulty,
    setDifficulty,
  };

  return (
    <PageContainer>
      <PageHeader
        title={t(`cs2:maps.${map.i18nKey}`, map.name)}
        ImageComponent={<HeaderIcon src={getMapImage(selectedMap)} />}
        className="header"
      />
      <GrenadeLineupsList
        {...lineupListProps}
        className="lineups-list"
        ref={lineupsRef}
      />
      <TransformWrapper
        {...{
          defaultScale: 1,
          limitToBounds: true,
          maxScale: 5,
          minScale: 0.5,
          wheel: {
            step: 0.5,
          },
        }}
      >
        {({ zoomIn, zoomOut }) => (
          <div className="map-container">
            <TransformComponent>
              <GrenadeLineupsMap
                selectedMap={selectedMap}
                selectedLineup={selectedLineup}
                setSelectedLineup={setSelectedLineup}
                grenadePosition={grenadePosition}
                lineups={filteredLineups}
              />
            </TransformComponent>
            <MapControls
              viewSettings={{}}
              setViewSettings={() => {}}
              zoomIn={zoomIn}
              zoomOut={zoomOut}
              options={[]}
            />
            {selectedLineupId && selectedLineup && showSelectedLineup ? (
              <GrenadeLineupsVideo
                selectedMap={selectedMap}
                selectedLineup={selectedLineup}
                setSelectedLineup={setSelectedLineup}
              />
            ) : null}
          </div>
        )}
      </TransformWrapper>
    </PageContainer>
  );
}

export function meta(params, searchParams) {
  const selectedMap = searchParams.get("map") || "de_mirage";
  const map = Object.entries(MAPS).find(([mapId]) => mapId === selectedMap)[1];

  return {
    title: ["cs2:meta.lineups.title", "Grenade Lineups"],
    description: [
      "cs2:meta.lineups.description",
      "Explore the best tactical lineups on all maps",
    ],
    subtitle: [
      `cs2:lineups.subtitle`,
      "Check out the best Grenade, Smoke, and Molly lineups for {{map}} in CS2!",
      { map: map.name },
    ],
  };
}

GrenadeLineups.fullBleed = true;

export default GrenadeLineups;
