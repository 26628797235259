import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";

import { appURLs } from "@/app/app-urls.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import { GAME_SYMBOL_CS2 } from "@/game-csgo/definition-symbol.mjs";
import CopyLinkIcon from "@/inline-assets/copy-link.svg";
import ShareIcon from "@/inline-assets/share.svg";
import VideoFrame from "@/shared/VideoFrame.jsx";
import globals from "@/util/global-whitelist.mjs";
import { normalizePathname } from "@/util/mod-pathname.mjs";

const LineupVideo = styled("div")`
  position: absolute;
  right: 0;
  top: 0;
  height: calc(5.75 * var(--sp-10));
  .video-frame {
    max-height: 100%;
    z-index: 3;
  }
  .footer {
    display: flex;
    flex-direction: row;
    margin-top: var(--sp-2);
    color: var(--shade0);
    gap: var(--sp-2);
    width: fit-content;
    float: right;
  }

  @container (width <= 400px) {
    .setpos {
      display: none;
    }
  }
`;

function GrenadeLineupsVideo({
  selectedMap,
  selectedLineup,
  setSelectedLineup,
}) {
  const { t } = useTranslation();
  return (
    <LineupVideo>
      <VideoFrame
        src={{
          mp4: `${appURLs.CDN_VIDEOS}/cs2/lineups/${selectedMap}/${selectedLineup.video.mp4}`,
        }}
        onClose={() => setSelectedLineup(null)}
        className="video-frame"
        startTime={undefined}
        footer={undefined}
        videoRef={undefined}
        isMuted
        onVolumeChange={undefined}
      />
      <div className="footer">
        <Button
          className="type-form--button flex-button setpos"
          iconLeft={<CopyLinkIcon width="18px" height="18px" />}
          emphasis="high"
          onClick={() =>
            globals.navigator.clipboard.writeText(selectedLineup.setpos)
          }
          data-tooltip={t("cs2:setpos.copied", "Setpos copied to clipboard!")}
          data-event="click"
        >
          {t("cs2:lineups.copySetPos", "Copy Setpos")}
        </Button>
        <Button
          className="type-form--button flex-button"
          iconLeft={<ShareIcon width="18px" height="18px" />}
          onClick={() =>
            globals.navigator.clipboard.writeText(
              normalizePathname(
                `${globals.location.origin}/${GAME_SHORT_NAMES[GAME_SYMBOL_CS2]}/tools/grenade-lineups?lineup=${selectedLineup.id}&map=${selectedMap}&grenade=${selectedLineup.grenade}`,
              ),
            )
          }
          data-tooltip={t(
            "common:share.copied",
            "Web link copied to clipboard!",
          )}
          data-event="click"
        >
          {t("common:share.share", "Share")}
        </Button>
      </div>
    </LineupVideo>
  );
}

export default GrenadeLineupsVideo;
