import React, { useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { removeSnackbarMessage, showSnackbar } from "@/app/actions.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import InfoIcon from "@/inline-assets/blitz-info-border.svg";
import DownloadIcon from "@/inline-assets/download.svg";
import VideoEmbed from "@/shared/VideoEmbed.jsx";

const TipIcon = styled(InfoIcon)`
  color: var(--shade2);
  cursor: pointer;
`;

const TipContainer = styled("div")`
  background: var(--shade10);
  border-radius: var(--br);
  color: var(--shade0);
  width: 420px;
  position: relative;

  video {
    object-fit: contain;
    position: relative;
    outline: none;
    width: 420px;
    border-radius: var(--br) var(--br) 0 0;
  }

  .shadow {
    position: absolute;
    top: 0;
    width: 420px;
    aspect-ratio: 16/9;
    background: radial-gradient(
      168.34% 100% at 50% 0%,
      rgba(255, 255, 255, 0) 57.2%,
      var(--shade10) 100%
    );
    border-radius: var(--br) var(--br) 0 0;
  }

  .content {
    width: 100%;
    padding: 0 var(--sp-6) var(--sp-6);
  }
`;

function JumpthrowTip() {
  const { t } = useTranslation();
  return (
    <TipContainer className="tooltip-plain">
      <VideoEmbed
        src={{ mp4: `${appURLs.CDN_VIDEOS}/cs2/jumpthrow.mp4#t=0.1` }}
        startTime={undefined}
        endTime={undefined}
        videoRef={undefined}
        isMuted
        onVolumeChange={undefined}
        videoPoster={undefined}
        expanded={false}
        controls={false}
        noControlsFullScreen
      />
      <div className="shadow" />
      <div className="content">
        <p className="type-subtitle--bold">
          {t("cs2:tips.howToJumpThrow", "How to Jump Throw Grenades")}
        </p>
        <p className="type-caption shade1">
          {t(
            "cs2:tips.jumpthrowDesc",
            "When you jump, release Mouse 1 (fire) at the peak of your jump. You should hear your in-game character make a ‘grunt’ noise.",
          )}
        </p>
      </div>
    </TipContainer>
  );
}

function useLineupTip(selectedLineup) {
  const jumpthrowTip = ReactDOMServer.renderToStaticMarkup(<JumpthrowTip />);
  useEffect(() => {
    if (
      selectedLineup &&
      selectedLineup.action.toLowerCase().includes("jumpthrow")
    ) {
      showSnackbar({
        id: "cs2-lineup-jump-throw-tip",
        priority: "low",
        text: [
          "cs2:lineupWithJumpThrow",
          "This lineup needs to be executed with a Jump Throw.",
        ],
        dismissable: false,
        Icon: () => <TipIcon data-tooltip={jumpthrowTip} />,
        actions: [
          {
            iconLeft: <DownloadIcon />,
            text: ["cs2:downloadConfigFile", "Download .cfg file"],
            href: `${appURLs.CDN_PLAIN}/blitz/cs2/warmup.cfg`,
            target: "_blank",
            dismiss: false,
          },
        ],
      });
    } else {
      removeSnackbarMessage("cs2-lineup-jump-throw-tip");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLineup]);
}

export default useLineupTip;
